import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const getToken = () => localStorage.getItem("access_token");
export const profileActionApi = createApi({
  reducerPath: "profileActionApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://devmodules.thewarrengroup.com/fa_marketshare/",
  }),
  endpoints: (builder) => ({
    getProfile: builder.mutation({
      query: (body) => ({
        url: "/profile",
        method: "GET",
        body,
        cache: "no-cache",
        headers: {
          authorization: `Bearer ${getToken()}`,
        },
      }),
    }),
    getUser: builder.query({
      query: (body) => ({
        url: "/user",
        method: "GET",
        body,
        cache: "no-cache",
        headers: {
          authorization: `Bearer ${getToken()}`,
        },
      }),
    }),
    createUser: builder.mutation({
      query: (body) => ({
        url: "/signup",
        method: "POST",
        body,
        cache: "no-cache",
        headers: {
          authorization: `Bearer ${getToken()}`,
        },
      }),
    }),
    updateUser: builder.mutation({
      query: (body) => ({
        url: `/user/${body.usr_id}`,
        method: "POST",
        body,
        cache: "no-cache",
        headers: {
          authorization: `Bearer ${getToken()}`,
        },
      }),
    }),
  }),
});
