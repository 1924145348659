import React, { useEffect } from "react";
import Navbar from "./Navbar";
import HeaderLogo from "../assets/images/twg_small_header.png";
import AddUserModal from "./AddUserModal";
import { Button } from "react-bootstrap";
import UsersTable from "./UsersTable";
import { useNavigate } from "react-router";
import { profileActionApi } from "../Redux/Action/Profile";

export default function AddProfiles() {
  // const { data: myUser, isLoading: loadingMyUser } =
  //   accountActionApi.useGetProfileQuery();
  const [getProfile, myUser] = profileActionApi.useGetProfileMutation();
  useEffect(() => {
    getProfile();
  }, []);

  const navigate = useNavigate();
  useEffect(() => {
    if (myUser.data?.usr_admin === "n") {
      navigate("/home");
    }
  }, [myUser]);

  const { data, isLoading, refetch } = profileActionApi.useGetUserQuery();

  const getUserFormData = () => ({
    usr_username: "",
    usr_email: "",
    usr_password: "",
    usr_confirm_password: "",
    is_lo: false,
    is_msm: false,
    usr_fname: "",
    usr_lname: "",
  });
  const [show, setShow] = React.useState(false);
  const [user, setUser] = React.useState(getUserFormData());
  const handleBackClick = () => {
    navigate("/home");
  }

  return (
    <div className="px-4">
      <div className="d-flex justify-content-start align-items-start">
        <img className="mr-2 mt-1 header-small-logo" src={HeaderLogo} />
        <Navbar />
      </div>
      <div className="d-flex my-4 justify-content-end align-items-start">
        <Button size="sm" onClick={() => setShow(true)}>Add User</Button>
        <Button size="sm" onClick={() => handleBackClick()}>Back</Button>
      </div>
      <div className="container">
        <UsersTable
          handleEdit={(data) => {
            setUser(data);
            setShow(true);
          }}
          data={data}
          isLoading={isLoading}
        />
      </div>
      <AddUserModal
        isEdit={!!user.usr_email}
        user={user}
        show={show}
        onHide={() => {
          refetch();
          setShow(false);
          setUser(getUserFormData());
        }}
      />
    </div>
  );
}
