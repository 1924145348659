import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
// import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import HeaderLogo from "../assets/images/twg_small_header.png";
import BasicTable from "./BasicTable";
import { queryActionApi } from "../Redux/Action/QueryAction";
import PDF from "../assets/images/pdf-48.png";
import CSV from "../assets/images/csv-48.png";
import EXCEL from "../assets/images/excel-48.png";
import TXT from "../assets/images/txt-48.png";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { profileActionApi } from "../Redux/Action/Profile";
import CommonModal from "../common/CommonModal";
import { Button } from "react-bootstrap";
import CommonLoader from "../common/CommonLoader";

export default function Home({ setData }) {
  const [getProfile, { data: user, isLoading }] = profileActionApi.useGetProfileMutation();

  const navigate = useNavigate();
  const [isQueryLoading, setIsQueryLoading] = useState(false);
  console.log(isQueryLoading, 'is query loading')
  const [isOpened, setIsOpened] = useState(true);
  const [showTable, setShowTable] = useState(false);
  const [tableData, setTableData] = useState({});
  console.log(tableData, 'table data')
  const [modalOpen, setModalOpen] = useState(false)
  const [csvFetch, csvResp] = queryActionApi.useDownloadcsvMutation();
  const [pdfFetch, pdfResp] = queryActionApi.useDownloadpdfMutation();
  const [txtFetch, txtResp] = queryActionApi.useDownloadtxtMutation();
  const [xlsFetch, xlsResp] = queryActionApi.useDownloadxlsMutation();
  useEffect(() => {
    if (localStorage.getItem("access_token")) {
      getProfile();
    }
  }, []);

  const download = (type = "") => {
    let fetch = pdfFetch;
    let payload = {
      cur_report_ary: tableData.report_ary,
      cur_report_header_ary: tableData.reportheader_ary,
      exporttype: type,
      brokerflag: false,
    };
    if (type === "CSV") {
      fetch = csvFetch; // csvFetch(payload);
    } else if (type === "TXT") {
      fetch = txtFetch; //  txtFetch(payload);
    } else if (type === "XLS") {
      fetch = xlsFetch; //  xlsFetch(payload).then((res) => console.log("res", res));
    } else {
      fetch = pdfFetch;
      payload = {
        ...payload,
        exporttype: "XLS",
      };
    }
    fetch(payload).then(({ data }) => {
      if (data.data) {
        let a = document.createElement("a");
        a.target = "_blank";
        a.href =
          "https://devmodules.thewarrengroup.com/na_lo/file/" + data.data;
        a.click();
      }
    });
  };
  const handleSwitchNavigation = () => {
    if (user?.is_lo === true) {
      const base = "https://lodevmodules.thewarrengroup.com";

      window.location.href = `${base}/sso/${localStorage.getItem(
        "access_token"
      )}`;
      setModalOpen(false);
    } else {
      setModalOpen(true);
    }

  };
  console.log("user", user);
  return (
    <>
    {isLoading ?
      (<CommonLoader style={{ height: "100vh", background: '#eeeeee' }} />) :
      <div>
        <Sidebar
          setTableData={setTableData}
          isOpened={isOpened}
          setShowTable={setShowTable}
          setIsQueryLoading={setIsQueryLoading}
        />
        <div className="content-page">
          <div className="d-flex justify-content-start align-items-start">
            <button
              className="navbar-toggler navbar-toggler"
              type="button"
              onClick={() => {
                document.body.classList.toggle("sidebar-icon-only");
                if (isOpened === false) {
                  setTimeout(() => {
                    setIsOpened((prev) => !prev);
                  }, 100);
                } else {
                  setIsOpened((prev) => !prev);
                }
              }}
            >
              <span className="mdi mdi-menu"></span>
            </button>
            <img className="mr-2 mt-1 header-small-logo" src={HeaderLogo} />
            <Navbar />
          </div>
          <div className="d-flex justify-content-end align-items-start my-2">
            {user?.usr_admin === "y" && (
              <Link className="d-flex my-auto mr-2" to="/users">
                View Users
              </Link>
            )}
            {/* {user?.is_lo && ( */}
            <button
              className="btn btn-primary btn-sm"
              onClick={handleSwitchNavigation}
            >
              switch to LO
            </button>
            {modalOpen && !user?.is_lo &&
              <CommonModal
                show={modalOpen}
                headerTitle="Permission for allow"
                body={`You dont have a permission for LO`}
                footer={
                  <Button className="btn btn-primary w-20 mb-4" onClick={() => setModalOpen(false)}>
                    Close
                  </Button>
                }
              />
            }
            {/* )} */}
          </div>
          {(!showTable || isQueryLoading) && (
            <p className="d-flex my-4 justify-content-center font-weight-normal">
              {isQueryLoading ? (
                "Loading..."
              ) : (
                <h6> Please run or load query to view results </h6>
              )}
            </p>
          )}
          {!isQueryLoading && showTable && (
            <>
              <div className="d-flex my-2 justify-content-end">
                {(csvResp.isLoading ||
                  pdfResp.isLoading ||
                  txtResp.isLoading ||
                  xlsResp.isLoading) && (
                    <div
                      class="spinner-border text-info mr-2"
                      style={{ height: 22, width: 22 }}
                      role="status"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                  )}
                <h5 className="mr-2">
                  <img
                    onClick={() => download("XLS")}
                    height={24}
                    src={EXCEL}
                  />
                  {/* <i className="mdi mdi-file-excel"></i> */}
                </h5>
                <h5 className="mr-2">
                  <img onClick={() => download("TXT")} height={24} src={TXT} />
                  {/* <i className="mdi mdi-file-document"></i> */}
                </h5>
                <h5 className="mr-2">
                  <img onClick={() => download("CSV")} height={24} src={CSV} />
                  {/* <i className="mdi mdi-file-check"></i> */}
                </h5>
                <h5 className="mr-2">
                  <img onClick={() => download("PDF")} height={24} src={PDF} />
                  {/* <i className="mdi mdi-file-pdf-box"></i> */}
                </h5>
              </div>
              <BasicTable
                tableData={tableData}
                isQueryLoading={isQueryLoading}
              />
            </>
          )}
        </div>
      </div>
      }
    </>
  );
}
