import React, { Component } from "react";
class Navbar extends Component {
  toggleOffcanvas() {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  }
  toggleRightSidebar() {
    document.querySelector(".right-sidebar").classList.toggle("open");
  }
  render() {
    return (
      <div className="small ml-auto mt-2 mr-2">
        <span className="mobile-hidden">
          <a
            href="https://www.thewarrengroup.com/data-collection-issues/"
            target="newwin"
            className="small text-danger mr-2"
            // style="color: red; font-weight: bold"
          >
            Coronavirus: Data Issues
          </a>{" "}
          | Warren Data Solutions |{" "}
          <a className="bold" href="https://www.thewarrengroup.com" target="twghome">
            www.thewarrengroup.com
          </a>{" "}
          | 617.896.5392
        </span>
      </div>
    );
  }
}

export default Navbar;
