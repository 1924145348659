import React, { useEffect, useState, useMemo } from "react";
import { Form } from "react-bootstrap";
import { FormikProvider, useFormik } from "formik";
import { useNavigate } from "react-router";
import * as Yup from "yup";
import moment from "moment";
import { toast } from "react-toastify";
import { usecode } from "../common/options";
import { loadQueryList } from "../common/options";
import CommonLoader from "../common/CommonLoader";
import { queryActionApi } from "../Redux/Action/QueryAction";
import { profileActionApi } from "../Redux/Action/Profile";
import SelectMultiple from "./FormElements/SelectMultiple";
import SingleSelect from "./FormElements/SingleSelect";
import SingleSelectQueryForm from "./FormElements/SingleSelectQueryForm";
import TextField from "./FormElements/TextField";
import DateRange from "./DateRange";
import SaveQueryModal from "./SaveQueryModal";
import LoadQueryModal from "./LoadQueryModal";
import AsyncSelectPaginateOptionsQueryForm from "./FormElements/AsyncSelectPaginateOptionsQueryForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

export default function QueryForm({
  setShowTable,
  setTableData,
  setIsQueryLoading,
}) {

  const [years, setYears] = useState([]);
  const [selectedRadio, setSelectedRadio] = useState(null);
  const [modalShow, setModalShow] = React.useState(false);
  const [showLoadQuery, setShowLoadQuery] = React.useState(false);
  const [list, setList] = useState([]);
  const [states, setStates] = useState([]);
  const [date, setDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [error, setError] = useState({
    dateError: "",
  });
  const [countyList, setCountyList] = useState([]);
  const [townList, setTownList] = useState([]);

  const [getProfile, { data: myUser, isLoading: isProfileLoading }] =
    profileActionApi.useGetProfileMutation();
  const [getState, { status: stateStatus, data: stateListData, isLoading: isStateLoading }] =
    queryActionApi.useGetStateMutation();
  const [getCounty, { status: countyStatus, data: countyListData, isLoading: isCountyLoading }] =
    queryActionApi.useGetCountyMutation();
  const [getTown, { status: townStatus, data: townListData, isLoading: isTownLoading }] =
    queryActionApi.useGetTownMutation();

  useEffect(() => {
    stateListData?.length > 0 && setStates(stateListData);
  }, [stateStatus, stateListData]);

  useEffect(() => {
    countyListData?.length > 0 && setCountyList(countyListData);
  }, [countyStatus, countyListData]);

  useEffect(() => {
    townListData?.length > 0 && setTownList(townListData);
  }, [townStatus, townListData]);

  const getInitialFormValues = () => ({
    isTimePeriodInYear: true,
    reporttype: "Ranking Report", // not found
    reportrank: { value: "$ Volume", label: "$ Volume" },
    loanpurpose: { value: "Any", label: "Any" },
    state: [],
    county: [],
    citytown: [],
    usecode: {
      value: {
        id: 0,
        usecode: "All",
        usecodegroup: "ANY",
        _uiSelectChoiceDisabled: false,
      },
      label: "All (Any)",
    },
    summarizeby: { value: "State Level", label: "State Level" },
    brokerlenderbypass: false,
    year: [],
    period: [],
    conventional: { value: "Any", label: "Any" },
    lendertype: { value: "Any", label: "Any" },
    lenderstodisplay: { value: "5", label: "5" },
    lender: "",
    lenders: [],
    loantypes: { value: "Any", label: "Any" },
    loantypessub: [{ value: "Any", label: "Any" }],
    refionly: true,
    excl_usahud: true,
    loantypessubbypass: false,
    loanmin: "",
    loanmax: "",
    customregion: false,
    isCounty: false,
    isTown: false,
    isState: false,
  });
  const [form, setForm] = useState(getInitialFormValues());
  const [loadQuery] = queryActionApi.useLoadQueryMutation();

  const getList = () =>
    loadQuery({
      usr_id: 1,
    }).then((res) => setList(res.data?.data?.data || loadQueryList.data.data));

  const setDateWithValue = (key, value) => {
    formik.setFieldValue("year", []);
    formik.setFieldValue("period", []);
    let state = {
      ...date,
      [key]: value,
    };
    if (!state.startDate && !state.endDate) {
      formik.setFieldValue("isTimePeriodInYear", true);
    } else {
      formik.setFieldValue("isTimePeriodInYear", false);
    }
    setDate(state);
  };
  const navigate = useNavigate();

  const getStateByCounty = (county, state) => {
    const parentStateCheck = state.map(x => {
      if(x.county===county){
        return x.state
      }
    });
    const data_one = countyListData.map(x => x.options.filter(y => y.value === county && y.state === parentStateCheck[0]))
    const data_two = data_one.filter(x => x.length > 0).map(y => y.map(p => p.state))[0]
    return data_two[0];
  }

  const validationSchema = Yup.object({
    isTimePeriodInYear: Yup.boolean(),
    isTown: Yup.boolean(),
    isCounty: Yup.boolean(),
    isState: Yup.boolean(),
    year: Yup.array().when("isTimePeriodInYear", {
      is: true,
      then: Yup.array().min(1, "Year is Required").required("Year is Required"),
    }),
    period: Yup.array().when("isTimePeriodInYear", {
      is: true,
      then: Yup.array()
        .min(1, "Period is Required")
        .required("Period is Required"),
    }),
    state: Yup.array().when("isState", {
      is: true,
      then: Yup.array()
        .min(1, "Please enter state")
        .required("Please enter state"),
    }),
    county: Yup.array().when("isCounty", {
      is: true,
      then: Yup.array()
        .min(1, "County is Required")
        .required("County is Required"),
    }),
    citytown: Yup.array().when("isTown", {
      is: true,
      then: Yup.array().min(1, "Town is Required").required("Town is Required"),
    }),
  });
  const [queryFetchAction, resp] = queryActionApi.useFetchQueryMutation();
  const [saveQueryAction, saveQueryResponse] =
    queryActionApi.useSaveQueryMutation();

  const handleSave = ({ name }) => {
    saveQueryAction({
      q_name: name,
      usr_id: 1,
      model: getPayload(formik.values),
    });
    getList();
    setModalShow(false);
    toast.success("Query Saved");
  };

  useEffect(() => {
    if (resp.data?.data) {
      setTableData(resp.data?.data);
      setShowTable(true);
    }
  }, [resp?.data]);

  const checkFormat = (checked_date) =>
    checked_date && moment(checked_date, "MM/DD/YYYY", true).isValid();
  const getDateDiff = (startDate, endDate) => {
    const sd = moment(startDate);
    const ed = moment(endDate);
    return (unitOfTime = "years") => ed.diff(sd, unitOfTime);
  };

  const getDate = (date) => moment(date, "MM/DD/YYYY").format("MM-DD-YYYY");
  const checkErrorInDate = (isTimePeriodInYear) => {
    if (!isTimePeriodInYear) {
      if (checkFormat(date.endDate) && checkFormat(date.startDate)) {
        const dateRangeDiff = getDateDiff(date.startDate, date.endDate);
        console.log("dateRangeDiff", dateRangeDiff("days"), dateRangeDiff());
        if (dateRangeDiff("days") < 0) {
          setError({ dateError: "Invalid date range" });
          return true;
        } else if (dateRangeDiff() > 10) {
          setError({
            dateError: "Please contact Admin for more than 10 years of data.",
          });
          return true;
        }
        setError({
          dateError: "",
        });
        return false;
      }
      setError({
        dateError: "Date range is required",
      });
      return true;
    }
    setError({
      dateError: "",
    });
    return false;
  };

  const getPayload = (values) => ({
    reporttype: "Ranking Report", // not found
    reportrank: values.reportrank?.value,
    reportformat: "HTML", // not found
    usecode: values.usecode.value,
    loanpurpose: values.loanpurpose.value,
    lendertype: values.lendertype.value, //"Finance/Mortgage Cos",
    lenderstodisplay: values.lenderstodisplay.value, //"10",
    lenders: values.lenders,
    ltext: "",
    loantypes: values.loantypes.value, //"Conforming",
    loantypessub: values.loantypessub.map((item) => item.value), //["ARM"],
    refionly: values.refionly,
    excl_usahud: values.excl_usahud,
    loantypessubbypass: values.loantypessubbypass,
    loanmin: values.loanmin,
    loanmax: values.loanmax,
    summarizeby: values.summarizeby.value,
    allowcustomregion: true,
    customregion: values.customregion,
    state: values.state.map((item) => ({
      state: item.value,
      _uiSelectChoiceDisabled: false,
    })),
    county: values.county.map((item) => ({
      state: item.state,
      county: item.label,
      _uiSelectChoiceDisabled: false,
    })),
    citytown: values.citytown.map((item) => ({
      state: getStateByCounty(item.county, values.county.map((item) => ({
        state: item.state,
        county: item.label,
        _uiSelectChoiceDisabled: false,
      }))),
      county: item.county,
      town: item.label,
      _uiSelectChoiceDisabled: false,
    })),
    zipcode: [],
    censustract: [],
    year: values.year ? values.year.map((item) => item.value) : [],
    period: values.period ? values.period.map((item) => item.value) : [], //["Annual"],
    reporting: {},
    lt: { lendertypesetup: true },
    brokerlenderbypass: values.brokerlenderbypass,
    isdaterange: !values.isTimePeriodInYear,
    daterange: values.isTimePeriodInYear
      ? {
        startdate: "",
        enddate: "",
      }
      : {
        startdate: getDate(date.startDate),
        enddate: getDate(date.endDate),
      },
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: form,
    validationSchema,
    onSubmit: async (values) => {
      if (!checkErrorInDate(values.isTimePeriodInYear)) {
        setIsQueryLoading(true);
        const payload = getPayload(values);
        await queryFetchAction(payload).then(() => {
          // resetForm();
          setIsQueryLoading(false);
        });
      }
    },
  });
  const { values } = formik;
  const selectedStateList = values.state.map((item) => item.value);
  const selectedCountyList = values.county.map((item) => item.label);
  let permissions = localStorage.getItem("permissions");
  permissions = permissions ? JSON.parse(permissions) : {};

  const iaAllCounty = values.county.find((item) => item.value === "All");
  const iaAllState = values.state.find((item) => item.value === "All");
  const hideDateRange = iaAllCounty || iaAllState;
  useEffect(() => {
    if (hideDateRange) {
      setDate({
        endDate: "",
        startDate: "",
      });
      formik.setFieldValue("isTimePeriodInYear", true);
    }
  }, [hideDateRange]);

  useEffect(() => {
    if (values.summarizeby.value !== "State Level") {
      if (selectedStateList.includes("All")) {
        setCountyList(countyListData);
      } else {
        const countyList = countyListData.filter((item) => {
          return selectedStateList.includes(item.label);
        });
        setCountyList([
          {
            label: "All",
            options: [
              {
                value: "All",
                label: "All",
              },
            ],
          },
          ...countyList,
        ]);
      }
    } else {
      setCountyList([]);
    }
  }, [values.state.length, values.summarizeby]);

  useEffect(() => {
    if (values.summarizeby.value !== "County Level" && values.summarizeby.value !== "State Level") {
      console.log(townListData);
      if (selectedCountyList.includes("All")) {
        setTownList(townListData);
      } else {
        const townList = townListData?.filter((item) => {
          return selectedCountyList.includes(item.label);
        });
        setTownList([
          {
            label: "All",
            options: [
              {
                value: "All",
                label: "All",
              },
            ],
          },
          ...townList,
        ]);
      }
    } else {
      setTownList([]);
    }
  }, [values.county.length, values.summarizeby]);

  useEffect(() => {
    if (selectedStateList.includes("All") && values.state.length > 1) {
      const state = values.state.filter((item) => item.value == "All");
      formik.setFieldValue("state", state);
    }
    if (selectedCountyList.includes("All") && values.county.length > 1) {
      const county = values.county.filter((item) => item.label == "All");
      formik.setFieldValue("county", county);
    }
  }, [values.state, values.county]);

  useEffect(() => {
    getProfile();
    let currentYear = new Date().getFullYear();
    let allYears = [];
    for (let i = 0; i < 10; i++) {
      allYears.push({
        label: currentYear,
        value: currentYear,
      });
      currentYear -= 1;
    }
    setYears(allYears);
  }, []);

  const resetForm = () => {
    setForm(getInitialFormValues());
    formik.resetForm();
    setDate({
      endDate: "",
      startDate: "",
    });
    setError({
      dateError: "",
    });
  };

  const loadSelected = () => {
    console.log("values", values.county);
    let data = selectedRadio?.q_parms || "";

    data = data.replaceAll("False", false);
    data = data.replaceAll("True", true);
    data = eval("(" + data + ")");
    const prev = getInitialFormValues();
    setForm(() => ({
      ...prev,
      reportrank: { value: data.reportrank, label: data.reportrank },
      loanpurpose: { value: data.loanpurpose, label: data.loanpurpose },
      usecode: {
        value: data.usecode,
        label: data.usecode.usecode,
      },
      brokerlenderbypass: data.brokerlenderbypass,
      lendertype: { value: data.lendertype, label: data.lendertype },
      lenderstodisplay: {
        value: data.lenderstodisplay,
        label: data.lenderstodisplay,
      },
      lenders: data.lenders,
      loantypes: { value: data.loantypes, label: data.loantypes },
      loantypessub: data.loantypessub
        ? data.loantypessub.map((item) => ({
          value: item,
          label: item,
        }))
        : [],
      refionly: data.refionly,
      excl_usahud: data.excl_usahud,
      loantypessubbypass: data.loantypessubbypass,
      loanmin: data.loanmin,
      loanmax: data.loanmax,
      summarizeby: { value: data.summarizeby, label: data.summarizeby },
      customregion: data.customregion,
      state: data.state
        ? data.state.map((item) => ({
          value: item.state,
          label: item.state,
        }))
        : [],
      county: data.county
        ? data.county.map((item) => ({
          state: item.state,
          label: item.county,
        }))
        : [],
      citytown: data.citytown
        ? data.citytown.map((item) => ({
          value: item,
          label: item.town,
        }))
        : [],
      year: data.year
        ? data.year.map((item) => ({
          value: item,
          label: item,
        }))
        : [],
      period: data.period
        ? data.period.map((item) => ({
          value: item,
          label: item,
        }))
        : [],
      isdaterange: data.isdaterange,
      isTimePeriodInYear: !data.isdaterange,
    }));
    if (data.isdaterange) {
      setDate({
        endDate: moment(data.daterange.enddate, "MM-DD-YYYY").toDate(),
        startDate: moment(data.daterange.startdate, "MM-DD-YYYY").toDate(),
      });
    } else {
      setDate({
        endDate: "",
        startDate: "",
      });
    }
    toast.success("Query has been loaded.\nRun to see Results!");
    setShowLoadQuery(false);
  };

  const {
    usr_county_permission = [],
    usr_state_permission = [],
    usr_citytown_permission = [],
    usr_quarter_permission = [],
    usr_year_permission = [],
  } = myUser || {};
  const filterByLabel = (arr = [], perm = []) => {
    return arr.filter((item) => perm.includes(item.label))
  };

  const filterByGroupOptions = (arr = [], labelCheck = [], valueCheck = []) => {
    let results = arr.filter((item) => labelCheck.includes('All') ? !labelCheck.includes(item.label) : labelCheck.includes(item.label));
    results = results.map((item) => ({
      ...item,
      options: item.options.filter((opt) => valueCheck.includes(opt.label)),
    }));
    return results;
  };

  useEffect(() => {
    usr_state_permission?.length > 0 && getCounty(usr_state_permission);
  }, [usr_state_permission, getCounty]);

  useEffect(()=>{
    if((stateListData?.length === 0 || !stateListData) && usr_state_permission?.length > 0){
      getState();
    }
  }, [stateListData, usr_state_permission, getState])

  useEffect(() => {
    if (usr_county_permission?.length > 0 && !getTown(usr_county_permission)) {
      usr_county_permission?.length > 0 && getTown(usr_county_permission);
    }
  }, [usr_county_permission, getTown]);

  return (
    <>
      {isProfileLoading || isStateLoading || isCountyLoading || isTownLoading ? <CommonLoader style={{ height: "100vh", position: "absolute", width: '100vw', background: '#eeeeee' }} /> :
        <>
          <LoadQueryModal
            list={list}
            getList={getList}
            onSave={handleSave}
            show={showLoadQuery}
            onHide={() => setShowLoadQuery(false)}
            setSelectedRadio={setSelectedRadio}
            loadSelected={loadSelected}
          />
          <SaveQueryModal
            onSave={handleSave}
            show={modalShow}
            onHide={() => setModalShow(false)}
          />
          <FormikProvider value={formik}>
            <form className="forms-sample">
              <div className="sidbar-options">
                <h5 className="card-title bg-secondary p-2">Report Options</h5>
                <div className="px-2">
                  <Form.Group>
                    <label htmlFor="exampleInputName1">MarketShare Rank By:</label>
                    <SingleSelect
                      name="reportrank"
                      placeholder="Rank by"
                      options={[
                        { value: "$ Volume", label: "$ Volume" },
                        { value: "# Loans", label: "# Loans" },
                      ]}
                    />
                  </Form.Group>
                  <Form.Group>
                    <label htmlFor="exampleInputName1">Loan Purpose:</label>
                    <SingleSelect
                      name="loanpurpose"
                      placeholder="Sort by"
                      options={[
                        { value: "Any", label: "Any" },
                        {
                          value: "Purchase",
                          label: "Purchase",
                        },
                        {
                          value: "Non Purchase",
                          label: "Non Purchase",
                        },
                      ]}
                    />
                  </Form.Group>
                  <Form.Group>
                    <label htmlFor="exampleInputName1">Property Type:</label>
                    <SelectMultiple
                      name="usecode"
                      placeholder="Select Property Type"
                      groupedOptions={usecode}
                    />
                  </Form.Group>
                  {/* <Form.Group>
              <label htmlFor="exampleInputName1">
                Government/Conventional :
              </label>
              <SingleSelect
                name="conventional"
                options={[
                  { value: "Any", label: "Any" },
                  { value: "Government", label: "Government" },
                  { value: "Conventional", label: "Conventional" },
                ]}
              />
            </Form.Group> */}
                </div>
                <h5 className="card-title bg-secondary p-2">Broker Option</h5>
                <div className="forms-sample px-2">
                  <Form.Group>
                    <label
                      htmlFor="brokerlenderbypass"
                      className="align-self-center"
                    >
                      Broker In Place of Lender:
                    </label>
                    <input
                      id="brokerlenderbypass"
                      name="brokerlenderbypass"
                      type="checkbox"
                      className="form-check-input ml-2 align-self-center mt-0"
                      onChange={(e) =>
                        formik.setFieldValue("brokerlenderbypass", e.target.checked)
                      }
                      checked={values.brokerlenderbypass}
                    />
                  </Form.Group>
                </div>
                <h5 className="card-title bg-secondary p-2">Lender Options</h5>
                <div className="forms-sample px-2">
                  <Form.Group>
                    <label htmlFor="exampleInputName1">Lender Type:</label>
                    <SingleSelect
                      name="lendertype"
                      placeholder="Select Lender Type"
                      defaultValue={{ value: "Any", label: "Any" }}
                      options={[
                        { value: "Any", label: "Any" },
                        {
                          value: "Banks",
                          label: "Banks",
                        },
                        {
                          value: "Credit Unions",
                          label: "Credit Unions",
                        },
                        {
                          value: "Finance/Mortgage Cos",
                          label: "Finance/Mortgage Cos",
                        },
                        {
                          value: "Other",
                          label: "Other",
                        },
                      ]}
                    />
                  </Form.Group>
                  <Form.Group>
                    <label htmlFor="exampleInputName1">Lenders To Display:</label>

                    <SingleSelect
                      name="lenderstodisplay"
                      placeholder="Lenders To Display"
                      options={[
                        { value: "5", label: "5" },
                        { value: "10", label: "10" },
                        { value: "15", label: "15" },
                        { value: "20", label: "20" },
                        { value: "25", label: "25" },
                        { value: "50", label: "50" },
                        { value: "100", label: "100" },
                        { value: "250", label: "250" },
                        { value: "500", label: "500" },
                        { value: "1000", label: "1000" },
                        { value: "2000", label: "2000" },
                      ]}
                    />
                  </Form.Group>
                  <Form.Group>
                    <label htmlFor="exampleInputName1">
                      Or Match these Lender(s):
                    </label>
                    <div className="d-flex">
                      <TextField
                        name="lender"
                        type="text"
                        placeholder="select Match these Lender(s)"
                        size="sm"
                      />
                      <button
                        onClick={() => {
                          formik.setFieldValue("lenders", [
                            ...formik.values.lenders,
                            formik.values.lender,
                          ]);
                          formik.setFieldValue("lender", "");
                        }}
                        className="mx-2 px-2 h-90"
                        type="button"
                      >
                        add
                      </button>
                    </div>
                    <div className="d-flex">
                      {formik.values.lenders.map((item, index) => (
                        <span className="badge badge-info mr-2">{item} <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faXmark} onClick={() => formik.setFieldValue(formik.values.lenders.splice(index, 1))} /></span>
                      ))}
                    </div>
                  </Form.Group>
                </div>
                <h5 className="card-title bg-secondary p-2">Loan Parameters</h5>
                <div className="forms-sample px-2">
                  <Form.Group>
                    <label htmlFor="exampleInputName1">Loan Types:</label>
                    <SingleSelect
                      name="loantypes"
                      placeholder="Loan Types"
                      defaultValue={{ value: "Any", label: "Any" }}
                      options={[
                        { value: "Any", label: "Any" },
                        {
                          value: "Conforming",
                          label: "Conforming",
                        },
                        {
                          value: "Jumbo",
                          label: "Jumbo",
                        },
                      ]}
                    />
                  </Form.Group>
                  <Form.Group>
                    <label htmlFor="exampleInputName1">
                      Additional Classifications:
                    </label>
                    <SingleSelect
                      name="loantypessub"
                      placeholder="Select Loan Classification(s)"
                      defaultValue={[{ value: "Any", label: "Any" }]}
                      options={[
                        { value: "ARM", label: "ARM" },
                        { value: "FHA", label: "FHA" },
                        { value: "Home Equity Loan", label: "Home Equity Loan" },
                        { value: "HELOC", label: "HELOC" },
                        { value: "Reverse", label: "Reverse" },
                        { value: "VHA", label: "VHA" },
                      ]}
                      isMulti
                    />
                  </Form.Group>
                  <Form.Group>
                    <label htmlFor="refionly" className="align-self-center">
                      Show Only Refi in Non-PMM:
                    </label>
                    <input
                      id="refionly"
                      name="refionly"
                      type="checkbox"
                      className="form-check-input ml-2 align-self-center mt-0"
                      onChange={(e) =>
                        formik.setFieldValue("refionly", e.target.checked)
                      }
                      checked={values.refionly}
                    />
                  </Form.Group>
                  <Form.Group>
                    <label htmlFor="excl_usahud" className="align-self-center">
                      Exclude USA-HUD:
                    </label>
                    <input
                      id="excl_usahud"
                      name="excl_usahud"
                      type="checkbox"
                      className="form-check-input ml-2 align-self-center mt-0"
                      onChange={(e) =>
                        formik.setFieldValue("excl_usahud", e.target.checked)
                      }
                      checked={values.excl_usahud}
                    />
                  </Form.Group>
                  <Form.Group>
                    <label
                      htmlFor="loantypessubbypass"
                      className="align-self-center"
                    >
                      Show Only HELOC/Home Equity Loan in Non-PMM:
                    </label>
                    <input
                      id="loantypessubbypass"
                      name="loantypessubbypass"
                      type="checkbox"
                      className="form-check-input ml-2 align-self-center mt-0"
                      onChange={(e) =>
                        formik.setFieldValue("loantypessubbypass", e.target.checked)
                      }
                      checked={values.loantypessubbypass}
                    />
                  </Form.Group>
                  <Form.Group>
                    <label htmlFor="exampleInputName1">Loan Range Minimum:</label>
                    <TextField
                      className="input-hide-spinner"
                      name="loanmin"
                      type="number"
                      placeholder="Select Loan Range Minimum"
                      size="sm"
                    />
                  </Form.Group>
                  <Form.Group>
                    <label htmlFor="exampleInputName1">Loan Range Maximum:</label>
                    <TextField
                      className="input-hide-spinner"
                      name="loanmax"
                      type="number"
                      placeholder="Select Loan Range Maximum"
                      size="sm"
                    />
                  </Form.Group>
                </div>

                <h5 className="card-title bg-secondary p-2">Geography</h5>
                <div className="forms-sample px-2">
                  <Form.Group>
                    <label htmlFor="exampleInputName1">Summarize Results By:</label>
                    <SingleSelect
                      name="summarizeby"
                      placeholder="Select Summarize Results"
                      defaultValue={{ value: "State Level", label: "State Level" }}
                      options={[
                        { value: "Nationwide", label: "Nationwide" },
                        { value: "State Level", label: "State Level" },
                        {
                          value: "County Level",
                          label: "County Level",
                        },
                        {
                          value: "Town Level",
                          label: "Town Level",
                        },
                      ]}
                      onChange={(value) => {
                        if (value.label === "Town Level") {
                          formik.setFieldValue("isCounty", true);
                          formik.setFieldValue("isTown", true);
                        } else if (value.label == "Nationwide") {
                          formik.setFieldValue("isCounty", false);
                          formik.setFieldValue("isState", false);
                          formik.setFieldValue("isTown", false);
                        } else if (value.label == "County Level") {
                          formik.setFieldValue("isState", true);
                          formik.setFieldValue("isCounty", true);
                          formik.setFieldValue("isTown", false);
                        } else if (value.label == "State Level") {
                          formik.setFieldValue("isState", true);
                          formik.setFieldValue("isCounty", false);
                          formik.setFieldValue("isTown", false);
                        }
                        formik.setFieldValue("summarizeby", value);
                      }}
                    />
                  </Form.Group>
                  <Form.Group>
                    <label htmlFor="exampleInputName1">
                      Run As Custom Region:{" "}
                    </label>
                    <input
                      name="customregion"
                      type="checkbox"
                      className="form-check-input ml-2 align-self-center mt-0"
                      checked={values.customregion}
                      onChange={(e) =>
                        formik.setFieldValue("customregion", e.target.checked)
                      }
                    />
                  </Form.Group>
                  <Form.Group>
                    <label className="align-self-center">State:</label>
                    {/* <SingleSelect
                  name="state"
                  placeholder="Select state"
                  options={ filterByLabel(states, usr_state_permission) }
                  isMulti
                  isDisabled={ values.summarizeby.value === "Nationwide" }
                /> */}
                    <SingleSelectQueryForm
                      name="state"
                      placeholder="Select state"
                      options={usr_state_permission.includes('All') ? states : filterByLabel(states, usr_state_permission)}
                      isMulti
                      isDisableField={values.summarizeby.value === "Nationwide"}
                      isDisabled={values.summarizeby.value === "Nationwide"}
                      closeOnSelect={false}
                      closeMenuOnSelect={false}
                      onSelectResetsInput={false}
                    />
                  </Form.Group>
                  <Form.Group>
                    <label className="align-self-center">County:</label>
                    {/* <SingleSelect
                  name="county"
                  placeholder="Select county"
                  options={ filterByGroupOptions(
                    countyList,
                    usr_state_permission,
                    usr_county_permission
                  ) }
                  isDisabled={
                    !countyList.length ||
                    values.summarizeby.value === "State Level"
                  }
                  isMulti
                /> */}
                    <SingleSelectQueryForm
                      name="county"
                      placeholder="Select county"
                      options={usr_county_permission.includes('All') ? countyList : filterByGroupOptions(
                        countyList,
                        usr_state_permission,
                        usr_county_permission
                      )}
                      isDisableField={
                        !countyList?.length ||
                        values.summarizeby.value === "State Level"
                      }
                      isDisabled={
                        !countyList?.length ||
                        values.summarizeby.value === "State Level"
                      }
                      isMulti
                      closeOnSelect={false}
                      closeMenuOnSelect={false}
                      onSelectResetsInput={false}
                    />
                  </Form.Group>
                  <Form.Group>
                    <label className="align-self-center">Town:</label>
                    {/* <SingleSelect
                  name="citytown"
                  placeholder="Select Town"
                  options={ filterByGroupOptions(
                    townList,
                    usr_county_permission,
                    usr_citytown_permission
                  ) }
                  isDisabled={
                    values.summarizeby.value !== "Town Level" ||
                    !values.county.length
                  }
                  isMulti
                /> */}
                    <AsyncSelectPaginateOptionsQueryForm
                      name="citytown"
                      placeholder="Select Town"
                      selectedCountyList={selectedCountyList}
                      usr_county_permission={usr_county_permission}
                      usr_citytown_permission={usr_citytown_permission}
                      townList={townList}
                      isMulti
                      isDisableField={
                        values.summarizeby.value !== "Town Level" ||
                        !values.county.length
                      }
                      isDisabled={
                        values.summarizeby.value !== "Town Level" ||
                        !values.county.length
                      }
                    />
                    {/*  */}
                  </Form.Group>
                </div>

                <h5 className="card-title bg-secondary p-2">Time Periods</h5>
                <div className="forms-sample px-2 mb-2">
                  <Form.Group className="mb-2">
                    <label className="align-self-center">Year(s):</label>
                    <SingleSelect
                      name="year"
                      placeholder="Select Year"
                      options={filterByLabel(years, usr_year_permission)}
                      isMulti
                      setValueCallback={() => {
                        setDate({
                          endDate: "",
                          startDate: "",
                        });
                        formik.setFieldValue("isTimePeriodInYear", true);
                        formik.setFieldError("year", undefined);
                      }}
                    />
                  </Form.Group>

                  <Form.Group>
                    <label className="align-self-center">Period(s):</label>
                    <SingleSelect
                      name="period"
                      placeholder="Select Period"
                      options={filterByLabel(
                        [
                          { value: "Annual", label: "Annual" },
                          { value: "YTD", label: "YTD" },
                          { value: "Q1", label: "Q1" },
                          { value: "Q2", label: "Q2" },
                          { value: "Q3", label: "Q3" },
                          { value: "Q1+Q2", label: "Q1+Q2" },
                          { value: "Q2+Q3", label: "Q2+Q3" },
                          { value: "Q3+Q4", label: "Q3+Q4" },
                          { value: "Q1+Q2+Q3", label: "Q1+Q2+Q3" },
                          { value: "January", label: "January" },
                          { value: "February", label: "February" },
                          { value: "March", label: "March" },
                          { value: "April", label: "April" },
                          { value: "May", label: "May" },
                          { value: "June", label: "June" },
                          { value: "July", label: "July" },
                          { value: "August", label: "August" },
                          { value: "September", label: "September" },
                          { value: "October", label: "October" },
                          { value: "November", label: "November" },
                          { value: "December", label: "December" },
                          { value: "Past 3 Months", label: "Past 3 Months" },
                          { value: "Past 6 Months", label: "Past 6 Months" },
                          { value: "Past 9 Months", label: "Past 9 Months" },
                          { value: "Past 12 Months", label: "Past 12 Months" },
                        ],
                        usr_quarter_permission
                      )}
                      isMulti
                      setValueCallback={() => {
                        setDate({
                          endDate: "",
                          startDate: "",
                        });
                        formik.setFieldValue("isTimePeriodInYear", true);
                        formik.setFieldError("year", undefined);
                      }}
                    />
                  </Form.Group>
                  {!hideDateRange && (
                    <>
                      <Form.Group className="text-center font-weight-bold mb-0">
                        <h4> or </h4>
                      </Form.Group>
                      <Form.Group>
                        <label className="align-self-center">Date Range</label>
                        <DateRange date={date} setDate={setDateWithValue} />
                        {error.dateError && (
                          <span className="text-danger">{error.dateError}</span>
                        )}
                      </Form.Group>
                    </>
                  )}
                </div>
              </div>
              <div className="twg-slide-lower-left-corner bg-secondary py-3">
                <div className="twg-full-btn-space row m-0 text-center">
                  <div className="w-50 p-2">
                    <button
                      type="button"
                      onClick={() => {
                        checkErrorInDate(values.isTimePeriodInYear);
                        formik.handleSubmit();
                      }}
                      className="btn btn-primary px-3 btn-sm"
                    >
                      Run Query
                    </button>
                  </div>
                  <div className="w-50 p-2">
                    <button
                      onClick={resetForm}
                      type="button"
                      className="btn btn-primary px-3 btn-sm"
                    >
                      Clear Query
                    </button>
                  </div>
                  <div className="w-50 p-2">
                    <button
                      onClick={() => setModalShow(true)}
                      type="button"
                      className="btn btn-primary px-3 btn-sm"
                    >
                      Save Query
                    </button>
                  </div>
                  <div className="w-50 p-2">
                    <button
                      onClick={() => setShowLoadQuery(true)}
                      type="button"
                      className="btn btn-primary px-3 btn-sm"
                    >
                      Load Query
                    </button>
                  </div>
                  <div className="w-100 p-2 pb-0">
                    <button
                      type="button"
                      className="btn btn-primary btn-sm btn-block px-3"
                      onClick={() => {
                        localStorage.clear();
                        navigate("/login");
                      }}
                    >
                      Log Out
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </FormikProvider>
        </>
      }
    </>
  );
}