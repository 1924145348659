import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { AsyncPaginate, reduceGroupedOptions } from "react-select-async-paginate";
import { toast } from "react-toastify";
import { Form } from "react-bootstrap";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { loadSelectOptions } from "../common/loadSelectOptions";
import FormError from "./FormError";
import SingleSelect from "./FormElements/SingleSelect";
import { profileActionApi } from "../Redux/Action/Profile";
import { queryActionApi } from "../Redux/Action";
import AsyncSelectPaginateOptions from "./FormElements/AsyncSelectPaginateOptions";
import AsyncSelectPaginate from "./AsyncSelectPaginate";

export default function AddUserModal(props) {
  const [userData, setUserData] = useState(props.user);
  const [years, setYears] = useState([]);
  const [states, setStates] = useState([]);
  const [countyList, setCountyList] = useState([]);
  const [townList, setTownList] = useState([]);

  const [getState, {status: stateStatus, data: stateListData}] = 
    queryActionApi.useGetStateMutation();
  const [getCounty, {status: countyStatus, data: countyListData}] = 
    queryActionApi.useGetCountyMutation();
  const [getTown, {status: townStatus, data: townListData}] = 
    queryActionApi.useGetTownMutation();

  useEffect(()=>{
    stateListData?.length>0 && setStates(stateListData);
  },[stateStatus, stateListData]);

  useEffect(()=>{
    townListData?.length>0 && setTownList(townListData);
  },[townStatus, townListData]);

  useEffect(()=>{
    countyListData?.length>0 && setCountyList(countyListData);
  },[countyStatus, countyListData]);

  const filterByLabel = (arr = [], perm = []) => {
    return arr.filter((item) => perm.includes(item.label))
  };
  
  useEffect(() => {
    getState();
  },[getState]);

  useEffect(()=>{
    getCounty(['All']);
  },[getCounty]);

  useEffect(()=>{
    getTown(['All']);
  },[getTown])
    
  const formik = useFormik({
    initialValues: {
      isEdit: props.isEdit,
      usr_state_permission: [],
      usr_year_permission: [],
      usr_quarter_permission: [],
      usr_county_permission: [],
      usr_citytown_permission: [],
      ...userData,
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      usr_username: Yup.string().required("User name is required"),
      usr_fname: Yup.string().required("First name is required"),
      usr_lname: Yup.string().required("Last name is required"),
      usr_email: Yup.string()
        .email("Invalid email")
        .required("Email is Required"),
      usr_password: Yup.string().when("isEdit", {
        is: false,
        then: Yup.string().required("Password is required"),
        otherwise: Yup.string().nullable(),
      }),
      usr_confirm_password: Yup.string().when("isEdit", {
        is: false,
        then: Yup.string().oneOf(
          [Yup.ref("usr_password"), null],
          "Passwords must match"
        ),
        otherwise: Yup.string().nullable(),
      }),
    }),
    onSubmit: (values, { setErrors }) => {
      if (!values.is_lo && !values.is_msm) {
        setErrors({ is_lo: "At least select one module." });
      } else {
        const action = props.isEdit ? updateAction : createAction;
        const usr_state_permission = values.usr_state_permission.map(
          (item) => item.label
        );
        const usr_year_permission = values.usr_year_permission.map(
          (item) => item.label
        );
        const usr_quarter_permission = values.usr_quarter_permission.map(
          (item) => item.label
        );
        const usr_county_permission = values.usr_county_permission.map(
          (item) => item.label
        );
        const usr_citytown_permission = values.usr_citytown_permission.map(
          (item) => item.label
        );
        const data = {
          ...values,
          usr_state_permission,
          usr_year_permission,
          usr_quarter_permission,
          usr_county_permission,
          usr_citytown_permission,
        };
        delete data.isEdit;
        action(data).then(() => {
          toast.success("User Saved!");
          props.onHide();
        });
      }
    },
  });
  const { handleChange, handleBlur, handleSubmit, errors, touched, values } =
    formik;
  useEffect(() => {
    if (props.user) {
      let user = { ...props.user };
      user = {
        ...user,
        usr_state_permission: user.usr_state_permission
          ? user.usr_state_permission.map((item) => ({
            label: item,
            value: item,
          }))
          : [],
        usr_year_permission: user.usr_year_permission
          ? user.usr_year_permission.map((item) => ({
            label: item,
            value: item,
          }))
          : [],
        usr_quarter_permission: user.usr_quarter_permission
          ? user.usr_quarter_permission.map((item) => ({
            label: item,
            value: item,
          }))
          : [],
        usr_county_permission: user.usr_county_permission
          ? user.usr_county_permission.map((item) => ({
            label: item,
            value: item,
          }))
          : [],
        usr_citytown_permission: user.usr_citytown_permission
          ? user.usr_citytown_permission.map((item) => ({
            label: item,
            value: item,
          }))
          : [],
      };
      setUserData(user);
    }
  }, [props.user]);
  useEffect(() => {
    let currentYear = new Date().getFullYear();
    let allYears = [];
    for (let i = 0; i < 10; i++) {
      allYears.push({
        label: currentYear,
        value: currentYear,
      });
      currentYear -= 1;
    }
    setYears(allYears);
  }, []);
  const [createAction] = profileActionApi.useCreateUserMutation();
  const [updateAction] = profileActionApi.useUpdateUserMutation();
  const selectedStateList = values.usr_state_permission.map(
    (item) => item.value
  );
  const selectedCountyList = values.usr_county_permission.map(
    (item) => item.value
  )

  // TODO: Remove value if its removed top level selection
  // useEffect(()=>{
  //   if(Array.isArray(values.usr_state_permission) && values.usr_state_permission.length === 0){
  //     formik.setFieldValue('usr_county_permission', []);
  //     formik.setFieldValue('usr_citytown_permission', []);
  //   }
  // },[values.usr_state_permission])

  // useEffect(()=>{
  //   if(Array.isArray(values.usr_county_permission) && values.usr_county_permission.length === 0){
  //     formik.setFieldValue('usr_citytown_permission', []);
  //   }
  // },[values.usr_county_permission])

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      className="custom-md-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.isEdit ? "Update" : "Add"} User
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormikProvider value={formik}>
          <form className="forms-sample">
            <Form.Group className="mb-3">
              <Form.Label>User name</Form.Label>
              <Form.Control
                name="usr_username"
                type="text"
                placeholder="Enter User name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.usr_username}
              />
              <FormError
                name="usr_username"
                errors={errors}
                touched={touched}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>First name</Form.Label>
              <Form.Control
                name="usr_fname"
                type="text"
                placeholder="Enter First name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.usr_fname}
              />
              <FormError name="usr_fname" errors={errors} touched={touched} />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Last name</Form.Label>
              <Form.Control
                name="usr_lname"
                type="text"
                placeholder="Enter Last name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.usr_lname}
              />
              <FormError name="usr_lname" errors={errors} touched={touched} />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                name="usr_email"
                type="email"
                placeholder="Enter Email address"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.usr_email}
              />
              <FormError name="usr_email" errors={errors} touched={touched} />
            </Form.Group>
            {!props.isEdit && (
              <>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="inputPassword5">Password</Form.Label>
                  <Form.Control
                    name="usr_password"
                    type="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <FormError
                    name="usr_password"
                    errors={errors}
                    touched={touched}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Confirm password</Form.Label>
                  <Form.Control
                    name="usr_confirm_password"
                    type="password"
                    placeholder="Enter Confirm password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <FormError
                    name="usr_confirm_password"
                    errors={errors}
                    touched={touched}
                  />
                </Form.Group>
              </>
            )}
            <Form.Group className="mb-3">
              <Form.Label>Can Access</Form.Label>
              <Form.Group className="mb-3">
                <label htmlFor="msm" className="align-self-center">
                  Market share module
                </label>
                <input
                  id="msm"
                  name="is_msm"
                  type="checkbox"
                  className="form-check-input ml-2 align-self-center mt-0"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  checked={values.is_msm}
                />
                <label htmlFor="lo" className="ml-2 align-self-center">
                  Loan module
                </label>
                <input
                  id="lo"
                  name="is_lo"
                  type="checkbox"
                  className="form-check-input ml-2 align-self-center mt-0"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  checked={values.is_lo}
                />
              </Form.Group>
              <FormError name="is_lo" errors={errors} touched={touched} />
            </Form.Group>
            <Form.Group className="mb-3">
              <label className="align-self-center">State Permission</label>
              <SingleSelect
                name="usr_state_permission"
                placeholder="Select state"
                options={states}
                isMulti
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <label className="align-self-center">County Permission:</label>
              <SingleSelect
                  name="usr_county_permission"
                  placeholder="Select county"
                  options={selectedStateList.includes('All') ? countyList: filterByLabel(
                    countyList,
                    selectedStateList
                  )}
                  isMulti
                />
            </Form.Group>
            <Form.Group>
              <label className="align-self-center">Town Permission:</label>
              <AsyncSelectPaginateOptions
                name="usr_citytown_permission"
                placeholder="Select Town"
                selectedCountyList={selectedCountyList}
                townList={townList}
                isMulti
              />
            </Form.Group>
            <Form.Group className="mb-2">
              <label className="align-self-center">Year(s) Permission:</label>
              <SingleSelect
                name="usr_year_permission"
                placeholder="Select Year"
                options={years}
                isMulti
                setValueCallback={() => {
                  // setDate({
                  //   endDate: "",
                  //   startDate: "",
                  // });
                  // formik.setFieldValue("isTimePeriodInYear", true);
                  // formik.setFieldError("year", undefined);
                }}
              />
            </Form.Group>
            <Form.Group>
              <label className="align-self-center">Period(s) Permission:</label>
              <SingleSelect
                name="usr_quarter_permission"
                placeholder="Select Period"
                options={[
                  { value: "Annual", label: "Annual" },
                  { value: "YTD", label: "YTD" },
                  { value: "Q1", label: "Q1" },
                  { value: "Q2", label: "Q2" },
                  { value: "Q3", label: "Q3" },
                  { value: "Q1+Q2", label: "Q1+Q2" },
                  { value: "Q2+Q3", label: "Q2+Q3" },
                  { value: "Q3+Q4", label: "Q3+Q4" },
                  { value: "Q1+Q2+Q3", label: "Q1+Q2+Q3" },
                  { value: "January", label: "January" },
                  { value: "February", label: "February" },
                  { value: "March", label: "March" },
                  { value: "April", label: "April" },
                  { value: "May", label: "May" },
                  { value: "June", label: "June" },
                  { value: "July", label: "July" },
                  { value: "August", label: "August" },
                  { value: "September", label: "September" },
                  { value: "October", label: "October" },
                  { value: "November", label: "November" },
                  { value: "December", label: "December" },
                  { value: "Past 3 Months", label: "Past 3 Months" },
                  { value: "Past 6 Months", label: "Past 6 Months" },
                  { value: "Past 9 Months", label: "Past 9 Months" },
                  { value: "Past 12 Months", label: "Past 12 Months" },
                ]}
                isMulti
                setValueCallback={() => {
                  // setDate({
                  //   endDate: "",
                  //   startDate: "",
                  // });
                  // formik.setFieldValue("isTimePeriodInYear", true);
                  // formik.setFieldError("year", undefined);
                }}
              />
            </Form.Group>
          </form>
        </FormikProvider>
      </Modal.Body>
      <Modal.Footer className="py-0">
        <Button className="btn btn-primary w-100 mb-4" onClick={handleSubmit}>
          {props.isEdit ? "Update" : "Add"} User
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
