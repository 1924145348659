export const changeObjectNullToString = (object = {}) => {
  let obj = {};
  for (const key in object) {
    if (Object.hasOwnProperty.call(object, key)) {
      const element = object[key];
      obj = {
        ...obj,
        [key]: element === null ? "" : element,
      };
    }
  }
  return obj;
};
