import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import TextField from "./FormElements/TextField";
import { Form } from "react-bootstrap";
import * as Yup from "yup";
import { FormikProvider, useFormik } from "formik";
const validationSchema = Yup.object({
  name: Yup.string().required("Query name is required."),
});
export default function SaveQueryModal(props) {
  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema,
    onSubmit: (values) => {
      console.log("values", values);
      props.onSave(values)
    },
  });
  useEffect(() => {
    if (!props.show) {
      formik.resetForm();
    }
  }, [props.show]);

  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Save Query</Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-0">
        <FormikProvider value={formik}>
          <Form.Group>
            <label htmlFor="exampleInputName1">Query name: </label>
            <TextField
              name="name"
              type="text"
              placeholder="Enter name"
              size="sm"
            />
          </Form.Group>
        </FormikProvider>
      </Modal.Body>
      <Modal.Footer className="py-0">
        <Button
          className="btn btn-primary btn-sm "
          onClick={formik.handleSubmit}
        >
          Save
        </Button>
        <Button className="btn btn-light btn-sm " onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
