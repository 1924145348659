import { configureStore } from "@reduxjs/toolkit";
// Or from '@reduxjs/toolkit/query/react'
import { setupListeners } from "@reduxjs/toolkit/query";
import { accountActionApi, queryActionApi, profileActionApi } from "../Action";
import commonReducer from "../Reducer/commonReducer";
export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    [queryActionApi.reducerPath]: queryActionApi.reducer,
    [accountActionApi.reducerPath]: accountActionApi.reducer,
    [profileActionApi.reducerPath]: profileActionApi.reducer,
    commonReducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      accountActionApi.middleware,
      queryActionApi.middleware,
      profileActionApi.middleware,
    ]),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
