import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

/**
 * All (Account) api End Points
 */
const getToken = () => localStorage.getItem("access_token");
export const accountActionApi = createApi({
  reducerPath: "accountActionApi",
  keepUnusedDataFor: 5,
  baseQuery: fetchBaseQuery({
    baseUrl: "https://devmodules.thewarrengroup.com/fa_marketshare/",
    headers: getToken()
      ? {
          authorization: `Bearer ${localStorage.getItem("access_token")}`,
        }
      : undefined,
  }),
  tagTypes: ["account", "get_user"],
  endpoints: (builder) => ({
    profileUpdate: builder.mutation({
      query: (body) => ({
        url: "profile_update",
        method: "POST",
        body,
      }),
      invalidatesTags: ["account"],
    }),
    getProfileUpdate: builder.query({
      query: (body) => ({
        url: "user_details",
        method: "POST",
        body,
      }),
      providesTags: ["account"],
    }),
    sso: builder.mutation({
      query: (body) => ({
        url: "/sso",
        method: "POST",
        body,
        headers: {authorization: `Bearer ${body?.sso_token}`},
      }),
      invalidatesTags: ["account"],
    }),
    login: builder.mutation({
      query: (body) => ({
        url: "/login",
        method: "POST",
        body,
      }),
      invalidatesTags: ["account"],
    }),
    getProfile: builder.mutation({
      query: (body) => ({
        url: "/profile",
        method: "GET",
        body,
        cache: "no-cache",
      }),
    }),
   
  }),
});
