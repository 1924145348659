import { useField } from "formik";
import React from "react";
import Select, { components } from "react-select";

const Group = (props) => <components.Group {...props} />;
export default function SelectMultiple({ groupedOptions, ...rest }) {
  const name = rest.name || "multipleSelect";
  const [field, meta, helpers] = useField(name);
  const { setValue, setTouched } = helpers;
  const { touched, error } = meta;

  return (
    <>
      <Select
        {...field}
        options={groupedOptions}
        components={{ Group }}
        onBlur={() => setTouched(true)}
        onChange={(option, actionMeta) => {
          setValue(option);
          console.log("actionMeta", actionMeta);
        }}
        {...rest}
      />
      {touched && error ? <span className="text-danger">{error}</span> : null}
    </>
  );
}
