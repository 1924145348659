import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleXmark,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import { accountActionApi } from "../Redux/Action/AccountSettingsAction";
import { changeObjectNullToString } from "../common/util";

export default function UsersTable({ handleEdit, data, isLoading }) {

  const [list, setList] = useState([]);
  useEffect(() => {
    if (data) {
      console.log("data", data);
      setList(data.map((item) => changeObjectNullToString(item)));
    }
  }, [data]);
  if (isLoading) {
    return <div>Loading...</div>;
  }
  return (
    <div className="table-responsive bg-white">
      <table className="table">
        <thead>
          <tr className="subheader">
            <th class="rptshd2">#</th>
            <th class="rptshd2">First Name</th>
            <th class="rptshd2">Last Name</th>
            <th class="rptshd2">Username</th>
            <th class="rptshd2">Email</th>
            <th class="rptshd2">Loan module Access</th>
            <th class="rptshd2">Market Share module Access</th>
            <th class="rptshd2">Action</th>
          </tr>
        </thead>
        <tbody>
          {list.map((item, index) => (
            <tr>
              <td>{index + 1}</td>
              <td>{item.usr_fname}</td>
              <td>{item.usr_lname}</td>
              <td>{item.usr_username}</td>
              <td>{item.usr_email}</td>
              <td>
                {item.is_lo ? (
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    fixedWidth
                    size="xl"
                    className="text-success"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faCircleXmark}
                    fixedWidth
                    size="xl"
                    className="text-secondary"
                  />
                )}
                {/* <Form.Control
                  className="h-25 user-select-none"
                  checked={item.is_lo}
                  type="checkbox"
                /> */}
              </td>
              <td>
                {item.is_msm ? (
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    fixedWidth
                    size="xl"
                    className="text-success"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faCircleXmark}
                    fixedWidth
                    size="xl"
                    className="text-secondary"
                  />
                )}
              </td>
              <td>
                <a href="javascript:;" onClick={() => handleEdit(item)}>
                  <FontAwesomeIcon icon={faPenToSquare} />
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
