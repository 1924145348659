import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";

export default function BasicTable({ tableData = {} }) {
  const { report_ary, reportheader_ary } = tableData;
  console.log("report_ary", report_ary);
  return (
    <div className="row">
      {report_ary?.map((tableBody, index) => {
        const [property, org, filterHeader, quarter, type, dateAndTime] =
          reportheader_ary[index];
        return (
          <div className="col-lg-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body p-2">
                <div className="d-flex justify-content-between mx-2">
                  <span className="w-65">{property} - Mortgage Marketshare Report</span>
                  <span className="">{quarter}</span>
                </div>
                <div className="d-flex justify-content-between m-2">
                  <span className="w-65">{filterHeader}</span>
                  <span className="">Report Time: {dateAndTime}</span>
                </div>
                <div className="d-flex justify-content-center pb-2 mb-2 border-dark border-bottom">
                  <span>Rank by {type}</span>
                </div>
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr class="subheader">
                        <th colspan="4">&nbsp;</th>
                        <th colspan="2" class="rptshd2">
                          All Mortgages
                        </th>
                        <th colspan="2" class="rptshd3">
                          Purchase Mortgages
                        </th>
                        <th colspan="2" class="rptshd4">
                          Non Purchase Mortgages
                        </th>
                        <th colspan="3" class="rptshd5">
                          Mkt Shr by $ Volume (%)
                        </th>
                      </tr>
                      <tr class="subheader">
                        <th>Lender Name</th>
                        <th>All</th>
                        <th>P</th>
                        <th>N</th>
                        <th>
                          Total
                          <br />
                          Value
                        </th>
                        <th>
                          Total
                          <br />
                          Number
                        </th>
                        <th>
                          Total
                          <br />
                          Value
                        </th>
                        <th>
                          Total
                          <br />
                          Number
                        </th>
                        <th>
                          Total
                          <br />
                          Value
                        </th>
                        <th>
                          Total
                          <br />
                          Number
                        </th>
                        <th>All</th>
                        <th>P</th>
                        <th>NP</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableBody.length ? (
                        tableBody.map((tableRow) => {
                          return (
                            <tr>
                              {tableRow.map((td) => (
                                <td>{td}</td>
                              ))}
                            </tr>
                          );
                        })
                      ) : (
                        <tr className="text-center font-weight-bold">
                          <td colspan="13">No Data Found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
