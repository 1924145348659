import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

/**
 * All (Account) api End Points
 */
const getToken = () => localStorage.getItem("access_token");
export const queryActionApi = createApi({
  reducerPath: "QueryAction",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://devmodules.thewarrengroup.com/fa_marketshare/",
  }),
  tagTypes: ["query"],
  endpoints: (builder) => ({
    fetchQuery: builder.mutation({
      query: (body) => {
        return ({
          url: "q",
          method: "POST",
          body,
          headers: {
            authorization: `Bearer ${getToken()}`,
          },
        })
      },
      invalidatesTags: ["query_post"],
    }),
    fetchTowns: builder.mutation({
      query: ({ body, off }) => ({
        url: "citytown",
        method: "POST",
        body,
        params: { limit: 10, offset: off || 1 },
        headers: {
          authorization: `Bearer ${getToken()}`,
        },
      }),
      invalidatesTags: ["query_post"],
    }),
    loadQuery: builder.mutation({
      query: (body) => ({
        url: "listq",
        method: "POST",
        body,
        headers: {
          authorization: `Bearer ${getToken()}`,
        },
      }),
      invalidatesTags: ["query_post"],
    }),
    deleteQuery: builder.mutation({
      query: (body) => ({
        url: "deleteq",
        method: "POST",
        body,
        headers: {
          authorization: `Bearer ${getToken()}`,
        },
      }),
      invalidatesTags: ["query_post"],
    }),
    saveQuery: builder.mutation({
      query: (body) => ({
        url: "saveq",
        method: "POST",
        body,
        headers: {
          authorization: `Bearer ${getToken()}`,
        },
      }),
      invalidatesTags: ["query_post"],
    }),
    updateQuery: builder.mutation({
      query: (body) => ({
        url: "updateq",
        method: "POST",
        body,
        headers: {
          authorization: `Bearer ${getToken()}`,
        },
      }),
      invalidatesTags: ["query_post"],
    }),
    downloadcsv: builder.mutation({
      query: (body) => ({
        url: "csv",
        method: "POST",
        body,
        headers: {
          authorization: `Bearer ${getToken()}`,
        },
      }),
      invalidatesTags: ["query_post"],
    }),
    downloadtxt: builder.mutation({
      query: (body) => ({
        url: "txt",
        method: "POST",
        body,
        headers: {
          authorization: `Bearer ${getToken()}`,
        },
      }),
      invalidatesTags: ["query_post"],
    }),
    downloadxls: builder.mutation({
      query: (body) => ({
        url: "xls",
        method: "POST",
        body,
        headers: {
          authorization: `Bearer ${getToken()}`,
        },
      }),
      invalidatesTags: ["query_post"],
    }),
    downloadpdf: builder.mutation({
      query: (body) => ({
        url: "pdf",
        method: "POST",
        body,
      }),
      invalidatesTags: ["query_post"],
    }),
    getState: builder.mutation({
      query: (body, off) => ({
        url: "state",
        method: "POST",
        body,
        headers: {
          authorization: `Bearer ${getToken()}`,
        },
        params: { limit: 60, offset: off || 1 },
      }),
      transformResponse: (response) => {
        let data = response.map(data => {
          return {label: data, value: data}
        });
        return [{label:'All', value:'All'}, ...data]
      },
    }),
    getCounty: builder.mutation({
      query: (body) => ({
        url: "county",
        method: "POST",
        body: { state: body?.state || body },
        headers: {
          authorization: `Bearer ${getToken()}`,
        },
      }),
      transformResponse: (response) => {
        const states = Object.keys(response);
        let data = states.map(item=>{
          const innerData = response[item]
          const optionData = innerData.map(subItem => {
          return{
              value: subItem,
              label: subItem,
              state: item
            }});
          return {
              label: item,
              options: optionData
          }
      })
        return [{
          label: "All",
          options: [
            {
              value: "All",
              label: "All",
              state: "All",
            },
          ],
        }, ...data]
      },
    }),
    getTown: builder.mutation({
      query: (body) => ({
        url: "town",
        method: "POST",
        headers: {
          authorization: `Bearer ${getToken()}`,
        },
        body: { county: body?.county || body }
      }),
      transformResponse: (response) => {
        const county = Object.keys(response);
        let data = county.map(item=>{
          const innerData = response[item]
          const optionData = innerData.map(subItem => {
          return{
              value: subItem,
              label: subItem,
              county: item
            }});
          return {
              label: item,
              options: optionData
          }
      })
        return [{
          label: "All",
          options: [
            {
              value: "All",
              label: "All",
              county: "All",
            },
          ],
        }, ...data]
      },
    })
  }),
});

export const { usePostStateCountyMutation } = queryActionApi