import { FormikProvider, useFormik } from "formik";
import React from "react";
import * as Yup from "yup";
import TextField from "./FormElements/TextField";
import { Col, Form, Row } from "react-bootstrap";
import moment from "moment";
import { queryActionApi } from "../Redux/Action/QueryAction";
import { toast } from "react-toastify";

const validationSchema = Yup.object({
  name: Yup.string().required("Query name is required."),
});
export default function UpdateQueryName(props) {
  const [updateQuery] = queryActionApi.useUpdateQueryMutation();

  const { q_name, q_lastmod } = props.data;
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: q_name,
    },
    validationSchema,
    onSubmit: (values) => {
      const payload = {
        q_id: props.q_id,
        usr_id: props.usr_id,
        q_name: values.name,
      };
      updateQuery(payload).then((res) => {
        toast.success("Query Updated");
      });
    },
  });
  return (
    <FormikProvider value={formik}>
      <Row className="d-flex align-items-center">
        <Col md={1} sm={1} lg={1}>
          <input
            value={props.q_id}
            name="radio"
            className="ml-2"
            type="radio"
            onChange={() => props.setSelectedRadio(props.data)}
          />
        </Col>
        <Col
          className="pr-0 d-flex justify-content-center align-items-center"
          md={7}
          sm={7}
          lg={7}
        >
          <TextField
            className="my-2"
            name="name"
            type="text"
            placeholder="Enter name"
            size="sm"
          />
          <button
            onClick={formik.handleSubmit}
            className="btn btn-primary mx-2 btn-sm btn-modal"
          >
            Update
          </button>
          <button
            className="btn btn-danger btn-sm btn-modal"
            onClick={props.onDelete}
          >
            Delete
          </button>
        </Col>

        <Col className="d-flex justify-content-center" md={4} sm={4} lg={4}>
          {q_lastmod && (
            <small>{moment(q_lastmod).format("MM/DD/yyyy h:mm a")}</small>
          )}
          {/* 12/28/2022 10:31 AM */}
        </Col>
      </Row>
    </FormikProvider>
  );
}
