import "./App.scss";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import Login from "./Components/Login";
import AuthRedirect from "./Components/AuthRedirect";
import Home from "./Components/Home";
import SSO from "./SSO";
import SSOError from "./SSOError";
import AddProfiles from "./Components/AddProfiles";
import { useState } from "react";

export default () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/" element={<AuthRedirect />}>
          <Route path="login" element={<Login />} />
          <Route path="home" element={<Home />} />
          <Route path="users" element={<AddProfiles />} />
        </Route>
        <Route path="/sso" element={<SSOError />} />
        <Route path="/sso/:token" element={<SSO />} />
        <Route path="*" element={<div>Not found</div>} />
      </>
    )
  );

  return <RouterProvider router={router} />;
};
