import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import TextField from "./FormElements/TextField";
import { FormikProvider, useFormik } from "formik";
// import Logo from '../assets/images/logo.svg'
import * as Yup from "yup";
import { accountActionApi } from "../Redux/Action/AccountSettingsAction";

export default function Login() {
  const [error, setError] = useState("")
  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    username: Yup.string().required("Username is required"),
    password: Yup.string().required("Password is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
  });
  const [loginAction, resp] = accountActionApi.useLoginMutation();
  useEffect(() => {
    if (resp.data?.data?.access_token && resp.data?.data?.refresh_token) {
      localStorage.setItem("access_token", resp.data?.data?.access_token);
      localStorage.setItem("refresh_token", resp.data?.data?.refresh_token);
    let permissions = {
      user_county_permission: resp.data?.data?.user_county_permission,
      user_state_permission: resp.data?.data?.user_state_permission,
      usr_citytown_permission: resp.data?.data?.usr_citytown_permission,
      usr_quarter_permission: resp.data?.data?.usr_quarter_permission,
      usr_year_permission: resp.data?.data?.usr_year_permission,
    };
    localStorage.setItem("permissions", JSON.stringify(permissions));
      navigate("/home");
    }else if(resp.data?.message){
      setError(resp.data.message)
    }
  }, [resp.data]);

  const formik = useFormik({
    initialValues: {
      username: "",
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: (values) => {
      loginAction({
        usr_username: values.username,
        usr_email: values.email,
        usr_password: values.password,
      });
    },
  });
  return (
    <FormikProvider value={formik}>
      <div className="d-flex align-items-center auth px-0">
        <div className="row w-100 mx-0">
          <div className="col-lg-5 mx-auto">
            <div className="auth-form-light text-left py-5 px-4 px-sm-5">
              <div className="brand-logo d-flex justify-content-center text-uppercase text-weight-normal">
                {/* <img src={Logo} alt="logo" /> */}
                <h4> Marketshare</h4>
              </div>
              <span className="text-weight-normal">
                Hello! let's get started
              </span>
              <h6 className="font-weight-light">Sign in to continue.</h6>
              <Form className="pt-3">
                <Form.Group>
                  <TextField
                    name="username"
                    type="text"
                    placeholder="Username"
                    size="lg"
                    className="h-auto"
                  />
                </Form.Group>
                <Form.Group>
                  <TextField
                    name="email"
                    type="email"
                    placeholder="Email"
                    size="lg"
                    className="h-auto"
                  />
                </Form.Group>
                <Form.Group>
                  <TextField
                    name="password"
                    type="password"
                    placeholder="Password"
                    size="lg"
                    className="h-auto"
                  />
                </Form.Group>
                {error &&  <span className="mt-4 text-danger">{error}</span>}
                <div className="mt-3">
                  <button
                    type="button"
                    className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                    onClick={formik.handleSubmit}
                  >
                    SIGN IN
                  </button>
                </div>
                <div className="mt-2">
                  <button
                    type="button"
                    className="btn btn-block btn-facebook auth-form-btn"
                    //   onClick={() => navigate("/home")}
                  >
                    <i className="mdi mdi-signal-variant mr-2"></i>Subscribe
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </FormikProvider>
  );
}
