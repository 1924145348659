import { useField } from "formik";
import React, { useEffect, useState } from "react";
import Select from "react-select";

export default function SingleSelect({
  options,
  placeholder,
  setValueCallback,
  ...rest
}) {
  const name = rest.name || "singleSelect";
  const [field, meta, helpers] = useField(name);
  const { setValue, setTouched } = helpers;
  const { touched, error } = meta;
  const [selectedValue, setSelectedValue] = useState(null);
  const [optionList, setOptionList] = useState([]);
  useEffect(()=>{
    setOptionList(options)
  },[options]);

  useEffect(()=>{
    if(Array.isArray(field?.value) && field?.value?.length > 0){
      setSelectedValue(field?.value)
    }
  },[field]);

  useEffect(()=>{
    if(field.name === "usr_state_permission" || field.name === "usr_county_permission"){
    var valList = selectedValue?.map(x=>x.value)
    if(Array.isArray(selectedValue) && selectedValue.length > 0 && valList.includes('All') && field.name !== "usr_state_permission"){
      let data_one = optionList?.filter(x => x?.label !== 'All')
      let data_two = data_one?.map(y => {
        let p = y?.options?.map(p => ({...p, isDisabled: true}));
        return {
          label: y?.label,
          options: p
        }
      })
      setOptionList(data_two)
    }
    
    else if(Array.isArray(selectedValue) && selectedValue.length > 0 && !valList.includes('All') && field.name !== "usr_state_permission") {
      let data_one = optionList;
      let data_two = data_one?.map(y => {
        let p = y?.options?.map(p => ({...p, isDisabled: false}));
        return {
          label: y?.label,
          options: p
        }
      })
      const dummyAll = [{
        "label": "All",
        "options": [
            {
                "value": "All",
                "label": "All",
                "state": "All"
            }
        ]
    }];
    const check = data_two.filter(data => data.label === 'All')
    setOptionList(check.length > 0 ? data_two : [...dummyAll, ...data_two])
    }

    else if(Array.isArray(selectedValue) && selectedValue.length === 0 && field.name !== "usr_state_permission") {
      let data_one = optionList;
      let data_two = data_one?.map(y => {
        let p = y?.options?.map(p => ({...p, isDisabled: false}));
        return {
          label: y?.label,
          options: p
        }
      })
      const dummyAll = [{
        "label": "All",
        "options": [
            {
                "value": "All",
                "label": "All",
                "state": "All"
            }
        ]
    }];
    const check = data_two.filter(data => data.label === 'All')
    setOptionList(check.length > 0 ? data_two : [...dummyAll, ...data_two])
    }
    
    else if(Array.isArray(selectedValue) && selectedValue.length > 0 && valList.includes('All') && field.name === "usr_state_permission") {
      let data_two = optionList?.map(p => ({...p, isDisabled: true}))
      setOptionList(data_two)
    }

    else if(Array.isArray(selectedValue) && selectedValue.length > 0 && !valList.includes('All') && field.name === "usr_state_permission") {
      let data_two = optionList?.map(p => ({...p, isDisabled: false}))
      setOptionList(data_two)
    }

    else if(Array.isArray(selectedValue) && selectedValue.length === 0 && field.name === "usr_state_permission") {
      let data_two = optionList?.map(p => ({...p, isDisabled: false}))
      setOptionList(data_two)
    }
  }
  },[selectedValue]);
  return (
    <>
      <Select
        {...field}
        placeholder={placeholder}
        name={field.name}
        options={optionList}
        onChange={(option) => {
          setValue(option);
          setValueCallback && setValueCallback(option);
          setSelectedValue(option||[]);
        }}
        onBlur={() => setTouched(true)}
        {...rest}
      />
      {touched && error ? <span className="text-danger">{error}</span> : null}
    </>
  );
}
